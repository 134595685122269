import { EntryCollection } from "contentful";
import type { GetStaticPaths, GetStaticProps, NextPage } from "next";
import { IPostFields } from "types/generated/contentful";
import { useRouter } from "next/router";
import {
  NewsletterContainer,
  PaginationContainer,
} from "styles/index/index.components";
import PostsList from "components/PostsList/PostsList";
import { getPages, getPagesCount } from "utils/pagination";
import { useEffect, useState } from "react";
import { getTags } from "contentful/getTags";
import { getPosts } from "contentful/getPosts";
import TopBar from "components/TopBar/TopBar";
import Pagination from "components/Pagination/Pagination";
import GeneralHead from "components/seo/GeneralHead";
import SafeArea from "components/legacy/SafeArea";
import NewsletterBox from "components/NewsletterBox/NewsletterBox";
import NavbarArea from "components/legacy/NavbarArea";

type HomeProps = {
  posts: EntryCollection<IPostFields>;
  totalPages: number;
  tags: string[];
};

const Home: NextPage<HomeProps> = ({ posts, totalPages, tags }) => {
  const router = useRouter();
  const [page, setPage] = useState(1);

  useEffect(() => {
    const queryPage = router.query?.page;
    const pageNumber = queryPage && queryPage[0] ? Number(queryPage) : 1;
    setPage(pageNumber);
  }, [router.query]);

  return (
    <>
      <GeneralHead />
      <NavbarArea>{<TopBar tags={tags} />}</NavbarArea>

      <PostsList posts={posts.items ?? []} page={page} />

      <PaginationContainer>
        <Pagination current={page} pages={totalPages} />
      </PaginationContainer>

      <SafeArea>
        <NewsletterContainer>
          <NewsletterBox />
        </NewsletterContainer>
      </SafeArea>
    </>
  );
};

export const getStaticProps: GetStaticProps<HomeProps> = async ({ params }) => {
  const paramsPage = params?.page ? Number(params.page[0]) : 1;

  if (isNaN(paramsPage)) {
    return {
      redirect: {
        destination: "/",
        permanent: true,
      },
    };
  }

  const page = Math.max(0, paramsPage - 1);

  const tags = await getTags();
  const posts = await getPosts(page);

  return posts.items === undefined || (posts.items.length === 0 && page > 0)
    ? {
        redirect: {
          destination: "/",
          permanent: true,
        },
      }
    : {
        props: {
          posts,
          tags,
          totalPages: getPagesCount(posts.total),
        },
        revalidate: page > 0 && 10,
      };
};

export const getStaticPaths: GetStaticPaths = async () => {
  const posts = await getPosts();
  const pages = getPagesCount(posts.total);
  const paths = getPages(pages).map((page) => ({
    params: {
      page: [page.toString()],
    },
  }));

  return {
    paths: [{ params: { page: [] } }, ...paths],
    fallback: "blocking",
  };
};

export default Home;
