import styled from "styled-components";
import { HorizontalGap } from "styles/gap";
import { down } from "utils/media";

export const TagsContainer = styled.div`
  display: flex;
  ${HorizontalGap(8)}
`;

export const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 80px;
`;

export const NewsletterContainer = styled.div`
  max-width: 864px;
  margin-top: 88px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;

  ${down("xl")} {
    margin-top: 80px;
  }

  ${down("md")} {
    margin-top: 72px;
  }

  ${down("sm")} {
    margin-top: 64px;
  }
`;
